.burger-button {
    width: 35px;
    height: 35px;
    background: #0f75bd;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    margin-left: 15px;

    &:hover {
        background-color: #187bc1;
    }

    >svg {
        width: 18px;

        >path {
            fill: white;
        }
    }
}

.rsvp-btn {
    background-color: #0F75BD;
    border-radius: 20px;
    border: none;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 15px;
    cursor: pointer;
    color: white !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    font-family: 'JDWave' !important;
    padding: 7px 20px !important;
}

button a {
    color: white;
    font-weight: bold;
    text-decoration: none;

    &:hover {
        font-weight: bolder;
    }
}

a button {
    color: white !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    font-family: 'JDWave' !important;
    padding: 7px 20px !important;

    &:hover {
        font-weight: bolder !important;
        color: grey !important;
    }
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 15px;
    width: 100%;
    justify-content: space-between;
    z-index: 999;
}

.main-menu {
    position: fixed;
    z-index: 10;
    width: 100%;
    top: -100%;
    transition: ease-in 1s;

    &.active {
        top: 0;
        display: block !important;
        transition: ease-in 0.6s;

        &>.css-11b3ww9-MuiPaper-root-MuiAppBar-root {
            display: none;
        }
    }
}

.nav-menu {
    transition: ease-in 1s;
    position: relative;
    z-index: -10;
    width: 100%;
    height: auto;
    margin-top: -2px;

    &.desktop {
        display: block;
    }

    &.mobile {
        display: none;
    }
}

.btn {
    position: relative;
    width: 197px;
    height: 34px;
    border-radius: 10px;
    border: none;
    font-size: 15px;
    font-weight: 400;
    font-family: "Athena";
    color: white;
    cursor: pointer;

    &.btn-1 {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #0F75BD;
    }

    &.btn-2 {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), #0F75BD;
    }

    &.btn-3 {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #0F75BD;
    }

    &.btn-4 {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), #0F75BD;
    }

    &.btn-5 {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), #0F75BD;
    }

    &.btn-6 {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), #0F75BD;
    }

    &.btn-7 {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #0F75BD;
    }

    &.btn-8 {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)), #0F75BD;
    }
}

.close-btn {
    width: 20px;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 99;
    cursor: pointer;

    >path {
        fill: white;
    }
}

.button-list {
    display: flex;
    position: relative;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 10px;
    align-content: center;
    align-items: center;
    width: 100%;
    background: #0f75bd;
    gap: 10px;
}

@media screen and (max-width: 592px) {
    .nav-menu {
        &.mobile {
            display: block !important;
        }

        &.desktop {
            display: none !important;
        }
    }
}