.history-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    overflow-x: scroll !important;
    overflow-y: hidden;
}

.history-title {
    position: fixed;
    margin-left: calc(-50% + 20px);
    margin-top: 50px;
    color: white;
    font-family: 'Athena';
    font-size: 36px;
    z-index: 1;
}

.desc {
    color: #0076C1;
    font-size: 17px;
    font-weight: 600;
    position: absolute;
    z-index: 10;
    width: 135px;
    font-family: 'JDWave';
    left: -4px;
    background: white;

    &.d1,
    &.d3,
    &.d5,
    &.d7,
    &.d9 {
        bottom: 177px !important;
    }

    &.d2,
    &.d4,
    &.d6,
    &.d8,
    &.d10 {
        top: 6px !important;
    }

    &.d1 {
        rotate: 6deg;
    }

    &.d2 {
        rotate: -6deg;
    }

    &.d3 {
        left: -30px !important;
        rotate: -8deg;
    }

    &.d4 {
        left: -30px !important;
        rotate: 8deg;
    }

    &.d5 {
        rotate: 4deg;
    }

    &.d6 {
        left: 8px !important;
        rotate: -12deg;
    }

    &.d7 {
        left: 12px !important;
        rotate: 12deg;
    }

    &.d8 {
        left: -32px !important;
        rotate: 10deg;
    }

    &.d9 {
        left: -32px !important;
        rotate: -10deg;
    }

    &.d10 {
        left: -20px !important;
        rotate: 3deg;
    }
}

.anim {
    animation: blink-animation 3s infinite both;
    -webkit-animation: blink-animation 3s infinite both;
}

@keyframes blink-animation {

    0%,
    50%,
    100% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

@-webkit-keyframes blink-animation {

    0%,
    50%,
    100% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

.entire-frise {
    position: absolute;
    top: 45%;
    left: 3%;
    height: 100px;
    padding-right: 100px;
}

.frise {
    position: absolute;
    top: 40%;
    left: 10%;
    width: 200%
}

.pola-and-stick {
    position: relative;

    &:nth-of-type(odd) {
        top: 0;
    }

    &:nth-of-type(even) {
        top: 272px;
    }

    &:nth-of-type(1) {
        left: 25px;

        .pola {
            rotate: 6deg;
        }
    }

    &:nth-of-type(2) {
        left: 160px;

        .pola {
            rotate: -6deg;
        }
    }

    &:nth-of-type(3) {
        left: 300px;

        .pola {
            rotate: -8deg;
        }
    }

    &:nth-of-type(4) {
        left: 440px;

        .pola {
            rotate: 8deg;
        }
    }

    &:nth-of-type(5) {
        left: 600px;

        .pola {
            rotate: 4deg;
        }
    }

    &:nth-of-type(6) {
        left: 740px;

        .pola {
            rotate: -12deg;
        }
    }

    &:nth-of-type(7) {
        left: 900px;

        .pola {
            rotate: 12deg;
        }
    }

    &:nth-of-type(8) {
        left: 1010px;

        .pola {
            rotate: 10deg;
        }
    }

    &:nth-of-type(9) {
        left: 1160px;

        .pola {
            rotate: -10deg;
        }
    }

    &:nth-of-type(10) {
        left: 1300px;

        .pola {
            rotate: 3deg;
        }
    }
}

.pola {
    position: absolute;
    z-index: 2;
    width: 120px;
    bottom: -7px;
    left: 0;
}

.stick {
    rotate: 0 !important;
}

.stick {
    position: relative;
    bottom: -20px;
    left: calc(50% + 7px);
    height: 52px;
    width: 4px;
    background-color: #0076C1;
}

.orange-sun {
    position: fixed;
    width: 120vw;
    height: 120vw;
    border-radius: 1000px;
    background-color: #FFCD88;
    margin-top: -100vh;
    margin-left: -30vw;
}

.frise-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding-top: 27px;
    padding-bottom: 27px;
}

.frise-img {
    position: relative;

    &.down {
        order: 2;

        .vectpr {
            bottom: 133px;
            left: 150px;
        }
    }

    &.up {
        order: 0;

        .vectpr {
            top: 137px;
            left: 150px;
        }
    }
}

.frise-txt {
    p {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.orange-logo {
    position: fixed;
    margin-top: 85dvh;
    left: 0;
    max-width: 393px;
}

.intersect-sun {
    position: fixed;
    width: 100%;
    left: 0;
}

.blue-arrow {
    position: absolute;
    top: 162%;
    left: 30px;
    width: 35px;
    height: 35px;
}

.blink-fast {
    animation: blink-fast 1.5s infinite;
}

@keyframes blink-fast {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

@media screen and (max-width: 592px) {
    .history-container {
        width: 100dvw !important;
        height: 100dvh !important;
    }

    .orange-sun {
        width: 100vw;
        height: 100vw;
        margin-top: -50vw;
        margin-left: 0;
    }
}