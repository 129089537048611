#modal-modal-title,
#modal-modal-description {
    color: black;
}

.hotel-container {
    background: linear-gradient(179.81deg, #00BFF5 62.49%, #FFCD88 94.76%);
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.hotel-title {
    font-family: "Athena";
    text-align: left;
    color: white;
    /* color: #080B5F;
    text-shadow: -1px 1px 0px #FFFFFF; */
    font-weight: 100;
    font-size: 46px !important;
    position: absolute;
    z-index: 1;
    left: 20px;
    top: 80px;
}

.hotel-img {
    position: relative;
    width: 30vh;
    height: 30vh;
    object-fit: cover;
    border-radius: 200px;
}

.hotel-img-complet {
    position: absolute;
    width: 30vh;
    height: auto;
    object-fit: cover;
}

strong {
    font-family: 'JDWave';
    font-size: 18px;
}

.hotel-group {
    display: flex;
    flex: 1 1 0px;
    flex-direction: column;
    color: white;
    padding-right: 20px;
    padding-left: 20px;
    text-align: left;
    gap: 10px;
    align-items: center;
}

.hotels {
    display: flex;
    position: absolute;
    height: calc(100% - 20px);
    top: 20px;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.text-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: 'JDWave';
    font-size: 20px;
    text-align: center;

    span,
    .details {
        padding-left: 3px;
    }
}

.hotel-btn {
    height: 40px;
    width: fit-content;
    padding: 5px 20px;
    margin-top: 10px;
    border-radius: 12px;
    border: none;
    color: white;
    background: #015ec5;
    font-size: 16px;
    cursor: pointer;

    &:hover {
        background: #0051ab;
    }

    &:active {
        background-color: #0051ab;
    }
}

.promo {
    font-weight: 500;
    font-size: 20px;
}

.details {
    display: none;
    text-decoration: underline;
    border: none;
    background: none;
    text-align: center;
    font-size: 18px;
    color: white;
    padding: 0;
    cursor: pointer;

    &:active {
        color: #015ec5;
    }
}

.details-desktop {
    margin-top: 20px;
    background-color: #ffffff47;
    padding: 10px;
    border-radius: 10px;
}

.hotel-name {
    font-family: 'Athena';
    font-size: 1.5rem;
    font-weight: bolder;
}

@media screen and (max-width: 592px) {
    .hotel-container {
        width: 100dvw !important;
        height: 100dvh !important;
    }

    .hotels {
        flex-direction: column;
        justify-content: space-around;
        height: 80%;
        top: 10%;
    }

    .hotel-group {
        flex-direction: row;

        &:nth-of-type(2) {
            padding-left: 15px !important;
            padding-right: 0 !important;

            .hotel-img {
                order: 2;
            }
        }
    }

    .hotel-img {
        width: 25vh !important;
        height: 25vh !important;
        margin-left: -12vw !important;

        &.odd {
            margin-right: -12vw !important;
            margin-left: 0vw !important;
        }
    }

    .hotel-btn {
        width: 100%;
    }

    .text-group {
        text-align: left !important;
    }

    .details {
        display: unset !important;
        text-align: left;
        font-family: 'JDWave';
    }

    .details-desktop {
        display: none !important;
    }
}