.crud-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    max-width: 100%;
    padding: 5%;
    flex-grow: 1;
    background: white;

    input[type="text"] {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        height: 40px;
        padding: 10px 16px;
        -webkit-transition: background-color 200ms ease, outline 200ms ease, color 200ms ease, -webkit-box-shadow 200ms ease;
        transition: background-color 200ms ease, outline 200ms ease, color 200ms ease, -webkit-box-shadow 200ms ease;
        transition: background-color 200ms ease, outline 200ms ease, color 200ms ease, box-shadow 200ms ease;
        transition: background-color 200ms ease, outline 200ms ease, color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease;
        border-radius: 8px;
        outline: none;
        background-color: #f3f3f4;
        color: #0d0c22;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;

        &:hover {
            border-color: rgba(0, 0, 0, 0.1);
            background-color: #fff;
            -webkit-box-shadow: 0 0 0 4px rgba(79, 76, 234, 0.1);
            box-shadow: 0 0 0 4px rgba(89, 76, 234, 0.1);
        }

    }

    label {
        display: block;
        color: #0d0c22;
        font-family: 'JDWave';
        -webkit-text-stroke: 0.1px;
        font-weight: bold;
    }
}

.delete-usr {
    background: none;
    border: none;
    cursor: pointer;
}

.input-flex {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;

    &.first {
        flex-direction: row !important;
        gap: 12px;
    }

    div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.add-user {
    display: block;
    width: 100%;
    margin-top: 22px;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 40px;
    padding: 10px 16px;
    -webkit-transition: color 200ms ease;
    transition: color 200ms ease;
    border: none;
    border-radius: 8px;
    outline: none;
    color: white;
    background: #0f75bd;
    font-family: 'Athena';
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &:active {
        background-color: #0051ab;
    }
}

.check-label {
    font-weight: 400 !important;
}

.checkboxes {
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
    gap: 20px;
}

.checkbox-option {
    display: flex;
    flex-direction: row !important;
    align-items: center !important;

    label {
        margin: 0 !important;
    }
}

.title {
    margin: 0;
    color: #0f75bd;
    font-family: 'Athena';
}

hr {
    border: solid 1px #0f75bd1f;
    width: 100%;
}

.add-guest {
    margin-top: 10px;
    border: none;
    background: no-repeat;
    font-size: 16px;
    font-weight: 700;
    color: #0f75bd;
    font-family: 'Athena';
    padding: 0;
    cursor: pointer;

    &:active {
        color: #0051ab;
    }
}

.contact-details {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: left;
    padding-left: 20px;
    padding-bottom: 55px;
    font-family: "JDWave";
    font-weight: bold;
    color: #0051ab;
    font-size: 21px;
}

textarea {
    width: 100%;
    height: 52px;
    background: #f3f3f4;
    border: none;
    border-radius: 10px;
}

.photo-contact {
    display: flex;
    flex-grow: 2;
    padding-right: 5%;
    flex-direction: column;
}

@media screen and (min-width: 592px) {
    .flex-form {
        display: flex;
        align-items: center;
        gap: 50px;
    }

    .contact-details {
        text-align: center;
    }
}

@media screen and (max-width: 592px) {
    .title {
        width: 100%;
    }

    .crud-container {
        /* margin-top: 60px; */
    }

    .arrowblock {
        bottom: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        left: unset;
    }

}

/* ======================================= CHECKBOXES ======================================= */

.checkbox-symbol {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    user-select: none;
}

.checkbox-container {
    box-sizing: border-box;
    background: #ffffff;
    color: #222;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
}

.checkbox-container * {
    box-sizing: border-box;
}

.checkbox-input {
    position: absolute;
    visibility: hidden;
}

.checkbox {
    user-select: none;
    cursor: pointer;
    padding: 6px 8px;
    border-radius: 6px;
    overflow: hidden;
    transition: all 0.3s ease;
    display: flex;
}

.checkbox:not(:last-child) {
    margin-right: 6px;
}

.checkbox:hover {
    background: rgba(0, 119, 255, 0.06);
}

.checkbox span {
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
}

.checkbox span:first-child {
    position: relative;
    flex: 0 0 18px;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    transform: scale(1);
    border: 1px solid #cccfdb;
    transition: all 0.3s ease;
}

.checkbox span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transform: translate3d(0, 0, 0);
}

.checkbox span:last-child {
    padding-left: 8px;
    line-height: 18px;
}

.checkbox:hover span:first-child {
    border-color: #0077ff;
}

.checkbox-input:checked+.checkbox span:first-child {
    background: #0077ff;
    border-color: #0077ff;
    animation: zoom-in-out 0.3s ease;
}

.checkbox-input:checked+.checkbox span:first-child svg {
    stroke-dashoffset: 0;
}

@keyframes zoom-in-out {
    50% {
        transform: scale(0.9);
    }
}