.houppa-container {
    /*  background: linear-gradient(179.81deg,
            #00BFF5 40.86%, #6FC4D0 48.84%,
            #D7CA9E 56.06%, #FFCD88 64.29%); */
    background-color: #00BFF5;
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.houppa-title {
    font-family: "Athena";
    font-size: 45px;
    margin-top: 30px;
    text-align: left;
    margin-left: 17px;
    color: white;
}

.houppa-date {
    font-family: "Athena";
    font-size: 28px;
    text-align: left;
    margin-left: 17px;
    color: white;
}

.pinpoint {
    width: 12px;
}

.houppa-location {
    color: #fff;
    font-family: JDWave;
    font-size: 30px;
    padding-right: 5%;
    /* padding-top: 40px; */
    text-align: right;
    width: 95%;
    position: absolute;
    z-index: 10;
}

.houppa-wave {
    width: 100vw;
    height: 50px;
    object-fit: fill;
}

.houppa-img {
    position: absolute;
    z-index: 2;
    bottom: -80px;
    right: -100px;
    width: 100%;
    max-width: 500px;
    object-fit: contain;
}

.sun {
    position: absolute;
    right: 5%;
    bottom: 40%;
    width: 100px;
    height: 100px;
    background-color: orange;
    border-radius: 50px;
}

@media screen and (max-width: 592px) {
    .houppa-container {
        width: 100dvw !important;
        height: 100dvh !important;
    }

    .houppa-title {
        font-size: 35px !important;
    }

    .houppa-date {
        font-size: 20px !important;
    }

    .houppa-location {
        padding-top: 40px;
        font-size: 25px;
    }

    .houppa-img {
        position: absolute;
        z-index: 3;
        right: -25% !important;
        bottom: 0 !important;
    }
}

@keyframes waveanim {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(-50vw, 250px);
    }

    100% {
        transform: translate(-100vw, 0);
    }
}

@keyframes waveanim2 {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(50vw, -90px);
    }

    100% {
        transform: translate(100vw, 0);
    }
}

.waveanim {
    height: 80vh;
    width: 100vw;
    position: absolute;
    bottom: 0;
    left: 0;
    animation: waveanim 40s linear infinite;

    .wave1 {
        position: absolute;
        width: 100vw;
        left: 0;
    }

    .wave2 {
        position: absolute;
        width: 100vw;
        left: 100vw !important;
    }
}

.waveanim2 {
    /* width: 100vw;
    height: 50vw; */
    height: 30vh;
    width: 100vw;
    position: absolute;
    bottom: 0;
    left: 0;
    animation: waveanim2 100s linear infinite;

    .wave1 {
        position: absolute;
        width: 100vw;
        left: 0;
    }

    .wave2 {
        position: absolute;
        width: 100vw;
        left: -100vw !important;
    }
}