.rsvp-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: fit-content;
    min-height: 100vh;
    overflow: hidden;
}

.fade {
    animation: fade-in 2s infinite;
}

.orange-cm {
    width: 35%;
    padding: 0 0 30px 0;
    max-width: 140px;
}

@keyframes fade-in {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.7;
    }
}

.form {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.ending-img {
    border-radius: 21px;
    padding-bottom: 15px;
    width: 100%;
    margin-top: 117px;
}

.bounce p {
    color: #0f75bd;
    font-weight: bold;
}

@media screen and (max-width: 592px) {

    .ending-img {
        border-radius: 0;
        margin-bottom: 15px;
        margin-top: 15px;
        width: 100vw;
        max-height: 250px;
        object-fit: cover;
    }

    .bounce {
        flex-direction: column;
        left: 40%;

        span {
            padding-right: unset
        }
    }
}