// Variables
$primary-color: #0f75bd;
$secondary-color: #ffffff;
$tertiary-color: #f4f4f4;

// Reset de base
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'JDWave' !important;
    background-color: $tertiary-color;
    color: $secondary-color;
    line-height: 1.6;
}

.wedding-list-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.round {
    border-radius: 100px;
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.end-logos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.arrow-end {
    position: unset !important;
    bottom: unset !important;
    padding-top: unset !important;
    padding-bottom: unset !important;
}

a {
    text-decoration: none;
    color: $secondary-color;
}

.orange-cm {
    width: 100% !important;
}

// Header
header {
    background-color: $primary-color;
    padding: 1rem 0;
}

.description-gift {
    color: $primary-color;
    font-weight: bold;
    font-size: larger;
    max-width: 700px;
    margin-bottom: 20px;
}

h1 {
    font-family: 'JDWave';
    color: $primary-color;
    font-size: 30px;
    padding: 0 !important;
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
}

nav ul {
    display: flex;
    list-style: none;
    padding-left: 0;
}

nav ul li {
    margin-left: 1rem;
}

nav ul li a:hover {
    color: $tertiary-color;
}

// Main
main {
    padding: 2rem;
}

.gifts {
    text-align: center;
}

.gifts-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.gift {
    background-color: $primary-color;
    width: 30%;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: 0.4s;

    &:hover {
        width: 35%;
        transition: 0.4s;
    }
}

.gift img {
    width: 75px !important;
    height: 75px !important;
    object-fit: cover;
    height: auto;
    border-radius: 5px;
    /* border-top-left-radius: 5px;
    border-top-right-radius: 5px; */
}

.gift h2 {
    font-size: 1.5rem;
}

.gift p {
    /* margin: 1rem; */
}

.btn-contribute {
    color: $primary-color !important;

    button {
        padding: 8px 20px !important;
        border: none;
        display: inline-block;
        background-color: $primary-color;
        color: $secondary-color !important;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        transition: background-color 0.3s ease, color 0.3s ease;
        outline: none;
    }
}

a .button:hover {
    background: unset;
    color: unset;
}

.btn-contribute button:hover {
    padding: 10px 22px !important;
    color: #ffffff !important;
    transition: 0.1s;
    cursor: pointer;
}

// Footer
footer {
    background-color: $primary-color;
    padding: 1rem 0;
    text-align: center;
}

.arrow-end {
    position: absolute;
    bottom: 0;
    padding-top: 0px;
    padding-bottom: 30px;
    cursor: pointer;
}

// Responsive Styles
@media (max-width: 768px) {
    nav ul {
        flex-direction: column;
    }

    nav ul li {
        margin: 0.5rem 0;
    }

    .gifts-container {
        flex-direction: column;
        align-items: center;
    }

    .gift {
        width: 90%;

        &:hover {
            width: 90%;
        }
    }

    .arrow-end {
        padding-bottom: 30px;
        position: relative;
    }

    .round {
        width: 100px;
        height: 100px;
    }
}