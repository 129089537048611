.stats-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 100vh;
    background-color: #0E0F2E;
    overflow-x: hidden !important;
}

.icon {
    width: 30px;
}

.stat-title {
    position: absolute;
    top: 11px;
    z-index: 1;
}

.stat-value {
    position: absolute;
    top: 31px;
    z-index: 1;
}

.calculated-stats {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 50px;
    gap: 50px;
    left: 5%;
    width: 90%;
}

.stat-box {
    position: relative;
    height: 100px;
    width: 100%;
    background-color: #191D43;
    border-radius: 10px;
    color: #E0E1E8;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-left: #184997 6px solid;
    overflow: hidden;
}

.ring {
    width: 107px;
    position: absolute;
    bottom: -27px;
    right: -20px;
    z-index: 0;
}

.sunn {
    width: 130px;
    position: absolute;
    bottom: -2px;
    right: -32px;
    z-index: 0;
}

.mairie {
    width: 110px;
    position: absolute;
    bottom: -2px;
    right: -20px;
    z-index: 0;
}

.page-title {
    position: absolute;
    color: #E8E8EB;
    font-weight: 600;
    font-size: 30px;
    top: 20px;
    left: 50px;
}

.center-elem {
    text-align: center;
}

.guest-table {
    position: relative;
    margin-top: 50px;
    margin-bottom: 70px;
    left: 5%;
    background-color: #191D43;
    width: 90%;
    height: fit-content;
    border-radius: 10px;

    table {
        color: #E0E1E8;

        td {
            padding: 6px;

            &:last-of-type {
                min-width: 55px;
            }
        }

        th {
            padding: 10px 2px;
        }

        .stripe {
            background-color: #262A50;
        }
    }
}

.stats-flex {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 400px;
    left: 5%;
    padding-top: 80px;
    gap: 50px;
}

.graph-1 {
    height: 100%;
    width: 100%;
    background-color: #191D43;
    border-radius: 10px;
}

.doughnuts {
    height: 400px;
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 50px;

    .dough-1 {
        background-color: #191D43;
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }

    .dough-2 {
        background-color: #191D43;
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
}

.guest-table-mobile {
    display: none;
}

.flex-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.excel-btn {
    border-radius: 25px;
    border: none;
    background: #066eb9;
    cursor: pointer;
    padding: 10px 20px !important;
}

.doublon-btn {
    font-family: 'JDWave';
    font-weight: bold;
    font-size: 16px;
    border-radius: 25px;
    border: none;
    background-color: white;
    color: #0E0F2E;
    padding: 10px 20px !important;
    cursor: pointer;

    &:hover {
        background-color: #E8E8EB;
    }
}

@media screen and (max-width: 600px) {
    .guest-table {
        display: none !important;
    }

    .guest-table-mobile {
        display: unset !important;
        color: white;
        margin: 0 20px;

        .blockk {
            text-align: center;
            border-radius: 10px;
            background-color: #191D43;
            padding: 10px;
            margin: 20px;
        }
    }

    .stats-flex {
        display: none;
    }

    .center-elem {
        padding: 10px;
    }

    table {
        border: 0;
    }

    table caption {
        font-size: 1.3em;
    }

    table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .tbody,
    table {
        background-color: #0E0F2E;
    }

    table tr {
        /* border-bottom: 3px solid #ddd; */
        display: block;
        margin-bottom: 30px;
        background-color: #191d43;
        border-radius: 5px;
    }

    table td {
        border-bottom: none;
        display: block;
        font-size: .8em;
        text-align: right;
    }

    table td::before {
        /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    table td:last-child {
        border-bottom: none;
    }

    .calculated-stats {
        gap: 15px !important;
        left: unset !important;
        width: calc(100% - 40px) !important;
        flex-direction: column !important;
        margin-left: 20px;
        margin-top: 80px;
    }

    .stat-box {
        width: 80%;
    }
}