.user-list {
    width: 100vw;
    overflow-x: hidden !important;
    padding-bottom: 45px;
}

h1 {
    padding: 30px;
}

@media screen and (max-width: 592px) {}