.sunset-container {
    background-color: white;
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.sunset-bg {
    height: 100%;
    width: 100%;
    background-image: url(../assets/backgrounds/sunset-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.sunset-bg-mobile {
    display: none;
}

.pinpoint {
    width: 12px;
}

.sunset-title {
    font-family: "Athena";
    position: absolute;
    top: 80px;
    right: 0 !important;
    background: #224EFF;
    border-radius: 60px 0px 0px 60px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 380px;
    height: 139px;
    text-align: left;
    padding-left: 30px;
}

.sun-title {
    margin-top: -5px;
    font-size: 45px;
}

.sun-date {
    font-size: 28px;
}

.sun-bubble {
    position: absolute;
    z-index: 3;
    bottom: 0px;
    left: 0;
    height: 150%;
    width: auto;
}

.bottom-part {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 55%;
}

.blue-ocean {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 38%;
    left: 0px;
    bottom: 0px;
    background: #224EFF;
}

.island-art {
    position: absolute;
    z-index: 4;
    bottom: 40px;
    color: white;
    right: 10px;
    font-size: 30px;
    font-family: 'JDWave';
    font-weight: 200;
}

@media screen and (max-width: 592px) {
    .sunset-container {
        width: 100dvw !important;
        height: 100dvh !important;
    }

    .sunset-bg-mobile {
        display: none !important;
    }

    .blue-ocean {
        height: 25%;
    }

    .sunset-bg-mobile {
        display: block;
        height: 100%;
        width: 100%;
        background-image: url(../assets/backgrounds/sunset-bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }

    .sun-bubble {
        height: 100%;
    }

    .sunset-title {
        width: 273px;
        height: 120px;
    }

    .sun-title {
        font-size: 36px;
    }

    .sun-date {
        font-size: 24px;
    }

    .island-art {
        font-size: 25px;
    }
}