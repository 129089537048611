.home-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-position: center;
    background: #32BEF1;
}

.arrow {
    height: 25px;
}

.arrowblock {
    position: absolute;
    bottom: 35px;
    text-decoration: none;
}

.bounce {
    animation: bounce 2s infinite;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
        font-family: 'JDWave';
        font-weight: bold;
        padding-bottom: 2px;
        color: white !important;
        text-decoration: none;
    }
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

.greek-building {
    max-width: 75%;
    max-height: 30%;
    align-self: flex-end;
}

.logo-cover {
    width: 30%;
}

.timer-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.date-full {
    color: white;
    font-family: "Athena";

    &:first-of-type {
        font-size: 4vw;
    }

    &:last-of-type {
        font-size: 2.8vw;
    }
}

.flex-home-container {
    display: none;
}

.logo-desktop {
    position: absolute;
    height: 70%;
    max-width: 90%;
    bottom: 15%;
    right: 0;
}

.timer-block.desktop {
    position: absolute;
    top: 85px;
    left: 100px;
}

@font-face {
    font-family: "Athena";
    src: url("../assets/fonts/Athena.ttf") format("truetype");
}

@font-face {
    font-family: "JDWave";
    src: url("../assets/fonts/simplicity.ttf") format("truetype");
}

@media screen and (min-width: 592px) and (max-width: 1000px) {
    .date-full {
        &:first-of-type {
            font-size: 6vw !important;
        }

        &:last-of-type {
            font-size: 3.8vw !important;
        }
    }

    .timer-block.desktop {
        top: 6vh !important;
        left: 7vw !important;
    }
}

@media screen and (max-width: 592px) {
    .bounce {
        flex-direction: row;

        span {
            padding-right: 5px
        }
    }

    .home-container {
        width: 100dvw !important;
        height: 100dvh !important;
    }

    .timer-block {
        width: 100%;
        margin: 0 !important;
        z-index: 3;

        &.desktop {
            display: none !important;
        }
    }

    .wedding-title {
        font-size: 50px;
    }

    .logo-cover {
        width: 90% !important;
    }

    .logo-desktop {
        display: none !important;
    }

    .flex-home-container {
        display: flex !important;
        height: 100%;
        width: 100%;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .date-full {
        font-size: 4vh !important;
    }

    .wave {
        height: 21px !important;
    }

    .arrowblock {
        bottom: 5px;
        left: 48%;
    }
}