.fp-is-overflow .fp-overflow.fp-auto-height,
.fp-is-overflow .fp-overflow.fp-auto-height-responsive,
.fp-is-overflow>.fp-overflow {
    overflow-x: hidden !important;
}

@media screen and (max-width: 592px) {
    #fp-nav {
        display: none !important;
    }
}