.stat-title {
    font-size: 20px;
    font-weight: 500;
}

.stat-value {
    font-size: 32px;
    font-weight: 700;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiTableContainer-root.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    margin-left: 3% !important;
    width: 95% !important;
    height: 60vh !important;
}

@media screen and (max-width: 592px) {
    .dashboard {
        flex-direction: column;
        gap: 20px;
    }
}