@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap');

.show-counter {
    width: fit-content;
    height: auto;
    display: flex;
    align-items: center;
    color: white;
    margin-top: 10px;
    justify-content: center;
    column-gap: 20px;
    font-family: "Athena";
}

.countdown {
    display: flex;
    align-items: flex-end;

    span {
        font-size: 2vw;
        color: #1674B4;
        font-weight: 500;
    }
}

@media screen and (min-width: 592px) and (max-width: 1000px) {
    .countdown {
        span {
            font-size: 2.7vw;
        }
    }
}

@media screen and (max-width: 592px) {
    .show-counter {
        flex-wrap: wrap;
    }

    .countdown:last-child {
        display: none !important;
    }

    .time-period {
        font-size: 3vh !important;
        margin-right: 5px;
    }

    .text-days {
        font-size: 2.5vh !important;
        padding-bottom: 2px;
    }
}