.mairie-container {
    background-color: #32BEF1;
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.mairie-title {
    font-family: "Athena";
    text-align: left;
    color: white;
    font-weight: 100;
    font-size: 45px;
    position: absolute;
    z-index: 1;
    left: 100px;
    top: 80px;
}

.mairie-date {
    font-family: "Athena";
    text-align: left;
    color: white;
    font-weight: 100;
    font-size: 28px !important;
    position: absolute;
    z-index: 1;
    left: 100px;
    top: 130px;
}

.mairie-loc-hour {
    display: flex;
    font-family: "JDWave";
    text-align: right;
    color: white;
    font-weight: 100;
    font-size: 30px !important;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    right: 100px;
    top: 210px;
}

.background-mairie-mobile {
    display: none;
}

.background-mairie {
    width: 100%;
    max-width: 100vw;
    max-height: 100vh;
    object-fit: cover;
    position: absolute;
    z-index: 0;
    left: 0;
    bottom: 0;
}

.mairie-waves {
    z-index: 1 !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

@media screen and (max-width: 592px) {
    .mairie-container {
        width: 100dvw !important;
        height: 100dvh !important;
    }

    .background-mairie,
    .mairie-waves {
        display: none;
    }

    .background-mairie-mobile {
        display: block;
        bottom: 0;
        position: absolute;
        z-index: 0;
        left: 0;
        width: 100%;
    }

    .mairie-title {
        font-size: 36px !important;
        left: 17px;
    }

    .mairie-date {
        font-size: 24px !important;
        left: 17px;
        top: 120px !important;
    }

    .mairie-loc-hour {
        right: 30px;
        font-size: 24px !important;
    }
}